import { EntityType, ProgressState, StateService, User, UserRole, UserUpdateModel } from "@glideroggan/common";
import { FASTElement, Observable, css, customElement, html, observable, volatile, when } from "@microsoft/fast-element";
import { ImageUploader } from "../../components/image-uploader.component";
ImageUploader

const template = html<EditMemberModal>/*html*/`
<style>
    @import url('assets/css/base.css');
</style>
${when(x => x.ready, html<EditMemberModal>/*html*/`
<div class="card">
    <div class="header">
        <img alt="profile picture" src="${x => x.updatedUser.imageUrl}">
        <div class="form-group ${x => x.hideForParent}">
            <input type="checkbox" class="form-input form-input-checkbox" id="swish" name="swish"
                @change="${(x, e) => x.onSwishChange(e.event)}"
                ?checked="${x => x.updatedUser.haveSwish}">    
            <label for="swish">Swish enabled?</label>
        </div>
    </div>
    <div class="content">
        <form class="form-block">
            <div class="form-group">
                <label for="firstname">Firstname</label>
                <input type="text" class="form-input" id="firstname" name="firstname"
                    invalid="${x => !x.firstnameValid}"
                    value="${x => x.updatedUser.firstname}"
                    @input="${(x, e) => x.onFirstnameInput(e.event)}">
                <span class="tooltip">Name can't be empty</span>
            </div>
            <div class="form-group">
                <label for="lastname">Lastname</label>
                <input type="text" class="form-input" id="lastname" name="lastname"
                    invalid="${x => !x.lastnameValid}"
                    value="${x => x.updatedUser.lastname}"
                    placeholder="${x => x.updatedUser.lastname}"
                    @input="${(x, e) => x.onLastnameInput(e.event)}">
                <span class="tooltip">Name can't be empty</span>
            </div>
            <div class="form-group ${x => x.hideForChild}">
                <label for="pass">Password</label>
                <input type="password" class="form-input" id="pass" name="pass"
                    @input="${(x, e) => x.onPasswordInput(e.event)}">
                <span class="tooltip">Password needs to be at least 3 characters long</span>
            </div>
            <div class="form-group">
                <label for="birth">Birthday</label>
                <input invalid="${x => !x.birthdateValid}" 
                    type="date" class="form-input" id="email" name="birth"
                    value="${x => x.updatedUser.birthDate?.toISOString().split('T')[0].toString()}"
                    @change="${(x, e) => x.onBirthDateChange(e.event)}">
                <span class="tooltip">Correct birthdate</span>
            </div>
            <div class="form-group">
                <label for="email">Email</label>
                <input type="email" class="form-input" id="email" name="email" required>
                <span class="tooltip">Not a valid email</span>
            </div>
            <div class="form-group">
                <label for="role">Role</label>
                <input type="text" disabled class="form-input" value="${x => x._data.role.toString()}">
            </div>
        </form>
    </div>

    <div class="footer">
        <image-uploader state="${x => x.imageUploadState}" uploadProgress="0">Change Image</image-uploader>
        <button class="button button-success" ?disabled="${x => !x.isValid || !x.dataChanged}"
            @click="${x => x.update()}">Update</button>
        <button class="button button-danger"
            @click="${x => x.delete()}">Remove</button>
        ${when(x => x.data.role == UserRole.Child, html<EditMemberModal>/*html*/`
            <button 
                class="button button-danger"
                @click="${x => x.$emit('passkey-empty', x.data.id)}">Empty passkeys</button>
        `)}
        
        
    </div>
</div>
`)}
`

const styles = css`
.hidden {
    display: none !important;
}
button {
    margin-left: 1em;
}
.card {
    .header {
        margin-bottom: 1em;
        display: grid;
        grid-template-columns: 1fr 1fr;
        input {
            vertical-align: middle;
        }
    }
    .content {
        .form-group {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: baseline;
        }
        
    }
    img {
        display: block;
        width: 100px;
        height: 100px;
        object-fit: cover;
    }
    .footer {
        margin-top: 1em;
    }
}
.form-block {
    position: relative;
    & .form-input[invalid="true"] {
        border-color: var(--danger-color);
    }
}
.tooltip {
    visibility: hidden;
    width: 100%; /* Adjust based on your content */
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 5px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 100%; /* Adjust based on your needs */
    left: 50%;
    transform: translateX(-50%);
    
    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}
.form-input[invalid="true"]:hover + .tooltip {
    visibility: visible;
    opacity: 1;
}
`

@customElement({
    name: 'edit-member-modal',
    template,
    styles
})
export class EditMemberModal extends FASTElement {
    @observable ready: boolean = false;
    // @observable data: User;
    @observable _data: User;
    @observable dataChanged: boolean = false;
    @observable updatedUser: UserUpdateModel = { id: -1, birthDate: new Date() }
    @observable isValid: boolean = false;
    @observable parentClass: string = 'hidden'
    @observable imageUploadState: 'idle' | 'loading' | 'success' | 'error' = 'idle'
    imageUploadButton: HTMLElement;
    constructor() {
        super();
        this.addEventListener('image-uploaded', (e) => this.onImageUploaded(e as CustomEvent<any>))
    }
    async connectedCallback(): Promise<void> {
        super.connectedCallback();

        // TODO: set password box to disabled if role is child
        this.updatedUser = { ... this.data };
        this.ready = true
    }
    get hideForParent(): string {
        return this.data.role == UserRole.Parent ? 'hidden' : ''
    }
    get hideForChild(): string {
        return this.data.role == UserRole.Child ? 'hidden' : ''
    }
    set data(value: User) {
        this._data = value
        this._data.birthDate = new Date(value.birthDate)
        this.updatedUser = { ...value }
        this.dataChanged = false
        Observable.notify(this, 'updatedUser')
        Observable.notify(this, '_data')
    }
    get data(): User {
        return this._data
    }
    get birthdateValid(): boolean {
        return this.updatedUser.birthDate ?
            this.updatedUser.birthDate < new Date() && this.updatedUser.birthDate > new Date(1900, 1, 1)
            : false
    }
    get firstnameValid(): boolean {
        return this.updatedUser.firstname ? this.updatedUser.firstname.length > 2 : false
    }
    get lastnameValid(): boolean {
        return this.updatedUser.lastname ? this.updatedUser.lastname.length > 2 : false
    }

    async delete() {
        this.$emit('delete', this.data.id)
    }
    validateModel(): boolean {
        if (this.updatedUser.firstname && this.updatedUser.firstname.length < 3) {
            console.log('firstname not valid')
            return false
        }
        if (this.updatedUser.lastname && this.updatedUser.lastname.length < 3) {
            console.log('lastname not valid')
            return false
        }
        if (this.updatedUser.password && (this.data.role == UserRole.Parent && this.updatedUser.password.length < 4)) {
            console.log('password not valid')
            return false
        }
        const now = new Date()
        // console.log('now', now)
        if (this.updatedUser.birthDate && (this.updatedUser.birthDate > now ||
            this.updatedUser.birthDate.getFullYear() < 1900)) {
            console.log('birthdate not valid')
            return false
        }

        return true
    }
    // TODO: fix the event type so we don't have to use any
    // it even exists one from that component
    async onImageUploaded(e: CustomEvent<any>) {
        // console.log('event to start upload', e.detail)
        e.stopPropagation()
        const button = this.shadowRoot?.querySelector('image-uploader') as HTMLElement
        const progress = await StateService.uploadImage(this, e.detail.imageData, e.detail.filename, EntityType.User, this.data.id)
        let src = ''
        for await (const step of progress) {
            console.debug('step', step)
            button.setAttribute('uploadProgress', step.step.toString())
            if (step.state == ProgressState.success) {
                src = step.data
            }
        }
        button.setAttribute('state', 'idle')
        this.updatedUser.imageUrl = src
        Observable.notify(this, 'updatedUser')
    }
    onSwishChange(event: Event) {
        const target = event.target as HTMLInputElement
        this.updatedUser.haveSwish = target.checked
        console.log('swish', this.updatedUser.haveSwish)
        Observable.notify(this, 'updatedUser')
        this.isValid = this.validateModel()
        this.dataChanged = true
    }
    onFirstnameInput(event: Event) {
        const target = event.target as HTMLInputElement;
        this.updatedUser.firstname = target.value
        Observable.notify(this, 'updatedUser')
        this.isValid = this.validateModel()
        // console.log('firstname', this.updatedUser.firstname, this.isValid)
        this.dataChanged = true
    }
    onLastnameInput(event: Event) {
        const target = event.target as HTMLInputElement;
        this.updatedUser.lastname = target.value
        Observable.notify(this, 'updatedUser')
        this.isValid = this.validateModel()
        // console.log('lastname', this.updatedUser.lastname, this.isValid)
        this.dataChanged = true
    }
    onPasswordInput(event: Event) {
        const target = event.target as HTMLInputElement
        this.updatedUser.password = target.value
        this.isValid = this.validateModel()
        // console.log('password', this.updatedUser.password, this.isValid)
        this.dataChanged = true
    }
    onBirthDateChange(event: Event) {
        const target = event.target as HTMLInputElement
        this.updatedUser.birthDate = new Date(target.value)
        Observable.notify(this, 'updatedUser')
        // console.log('birthdate', this.updatedUser.birthDate)
        this.isValid = this.validateModel()
        // console.log('birthdate', this.updatedUser.birthDate, this.isValid)
        this.dataChanged = true
    }
    async update() {
        this.updatedUser.id = this.data.id
        this.$emit('update', this.updatedUser)
    }
}

